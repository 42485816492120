.around-dropdown {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.filter-container,
.filter-ctas {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 1rem;
}
.filter-subtitle {
  padding: 0 9px !important;
  font-style: italic;
  margin-bottom: 5px;
}
.filter-ctas {
  height: 3rem;
}

.filter-options {
  padding: 9px 0px;
  width: 13rem;
  max-height: 35rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.filter-container {
  flex-wrap: wrap;
}

.filter-menu .header {
  padding: 0 0px 0px 9px;
}

.filter-menu .header h6,
.filter-subtitle,
.filter-options span {
  font-size: var(--headingM) !important;
}
