.flash-card:has(.card-front) {
  background-image: linear-gradient(
      180deg,
      white 3rem,
      #8e161e98 calc(3rem),
      #8e161e98 calc(3rem + 2px),
      transparent 1px
    ),
    repeating-linear-gradient(
      0deg,
      transparent,
      transparent 1.5rem,
      #ddd 1px,
      #ddd calc(1.5rem + 1px)
    );
  padding: 0.4rem;
  z-index: 1;
  cursor: pointer;
}
.card-front {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: -webkit-fill-available;
}
.flash-card h3 {
  text-align: center;
  margin-bottom: 0px;
}

.flash-card p {
  font-size: 0.8rem;
  margin-bottom: 0px;
  overflow-wrap: break-word;
  line-height: 1.5rem;
  overflow-y: auto;
}

.flash-card {
  background-color: #fbfbff;
  box-shadow: var(--box-shadow);
  height: 12.3rem;
  width: 360px;
  position: relative;
  overflow: auto;
  min-width: 285px;
  border-radius: var(--borderRadius);
}

.card-back {
  display: flex;
  height: 100%;
  width: 100%;
  overflow-wrap: anywhere;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 0.8rem;
}
.no-cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.no-cards h1 {
  margin-bottom: 0px;
}
#no-cards-view {
  text-align: center;
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 5rem 0rem;
}
.flash-card:has(.card-front) .more {
  float: right;
  padding: 0;
}
.flash-card:has(.card-back) .more {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.4rem;
}
.study-icon {
  position: absolute;
  bottom: 0.4rem;
  right: 0.4rem;
}
@media screen and (max-width: 476px) {
  .flash-card,
  .card-container {
    width: 100%;
  }
}
