div.around-active-exam {
  background-color: #fbfbff;
  display: flex;
  border-radius: var(--borderRadius);
  box-shadow: var(--box-shadow);
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 auto;
}
div.active-container {
  display: flex;
  padding: 1rem;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

div.around-active-exam div.top-part img.logo-image {
  opacity: 0.5;
  width: 5%;
  height: auto;
}
div.around-active-exam div.top-part {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0rem 0.2rem;
}

div.middle-part {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  overflow-y: auto;
  height: 28rem;
  justify-content: center;
  padding: 0.5rem;
}
div.middle-part h6.question,
div.middle-part h4.final {
  text-align: center;
  word-break: break-word;
  white-space: normal;
  margin: 0rem 1.5rem 1rem 1.5rem;
}
div.middle-part h4.final {
  margin: 0rem;
}
div.middle-part button.choices:not(:has(.highlighted)) {
  border: solid 1px #0000003b;
}
div.middle-part button.choices {
  padding: 0.5rem;
  border-radius: var(--borderRadius);
  word-wrap: break-word;
  background-color: transparent;
  margin-bottom: 0.5rem;
  display: flex;
  width: 100%;
  flex-direction: row;
  text-align: start;
  align-items: center;
  word-break: break-word;
  white-space: normal;
  transition: all 0.2s linear;
}
div.middle-part button.choices:hover {
  box-shadow: var(--box-shadow);
}
div.middle-part button.choices.highlighted input::before {
  opacity: 1; /* Show the inner circle when highlighted */
}
div.middle-part button.choices.highlighted[disabled] input {
  background: inherit;
}
div.middle-part button.choices p {
  display: flex;
  align-items: center;
}

div.middle-part button.choices p:last-child::after {
  visibility: visible;
}
div.bottom-part {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #0b4f6c;
  height: 3rem;
  align-items: center;
  border-radius: 0 0 var(--borderRadius) var(--borderRadius);
  padding: 0.5rem;
}

div.bottom-part button {
  width: 12rem;
  height: 2rem;
}

p.logo-stamp {
  border-radius: 20px;
  display: flex;
  align-items: center;
  direction: ltr;
  padding: 4px 8px;
  text-transform: capitalize;
  font-weight: 400;
  background: white;
  box-shadow: var(--box-shadow);
  justify-content: center;
}
a.around-stamp {
  text-decoration: none;
  width: 12rem;
  display: flex;
  height: 2rem;
  color: var(--text);
  align-items: center;
}
a.around-stamp:hover {
  color: var(--blue);
}
p.logo-stamp b {
  color: var(--red);
}
p.logo-stamp img {
  width: 10%;
}

div#result-alert.correct:before,
div#result-alert.wrong:before {
  appearance: all;
  border: var(--green) solid 1px;
  width: 10rem;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
  height: 2rem;
  background-color: var(--green);
  border-radius: var(--borderRadius);
  content: "Correct";
  font-size: small;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
div#result-alert.wrong:before {
  content: "Incorrect";
  border: var(--red) solid 1px;
  background-color: var(--red);
}

div#result-alert {
  appearance: none;
}

@media screen and (max-width: 980px) {
  div.around-active-exam div.top-part img.logo-image {
    width: 8%;
  }
  div.middle-part h4.final {
    font-size: var(--headingM);
  }
}
@media screen and (max-width: 550px) {
  div.bottom-part button,
  a.around-stamp {
    width: 8rem;
  }
}
@media screen and (max-width: 450px) {
  div.bottom-part button,
  a.around-stamp {
    width: 6rem;
  }
  p.logo-stamp img {
    width: 15%
  }
}
