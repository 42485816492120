span.access-container form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
}
span.access-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.access-header div:has(img) {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.access-header img {
  width: 5rem;
}
.access-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form-container {
  background-color: white;
  box-shadow: var(--box-shadow);
  border: none;
  border-radius: var(--borderRadius);
  padding: 1rem 2rem;
  width: 30rem;
}
h2.form-items {
  margin-bottom: 0rem;
}
.cta-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  gap: 0.5rem;
}
p.note {
  font-size: 12px;
  width: 100%;
  text-align: center;
}

.name-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

@media screen and (max-height: 450px) and (orientation: landscape) {
  .form-container {
    overflow: auto;
    width: 100%;
  }
  .name-container div {
    width: 100%;
  }
}
@media screen and (max-width: 547px) {
  .form-container {
    overflow: auto;
    width: 100%;
  }
  .name-container {
    flex-direction: column;
    align-items: stretch;
  }
}
@media screen and (max-width: 406px) {
  .cta-actions {
    flex-direction: column;
  }
}
