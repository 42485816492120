:root {
  --box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);

  --text: #060f16;
  --red: #8e161f;
  --blue: #0c4f6c;
  --gold: #8b6e10;
  --icon: #7d7d7d;
  --ghostBlue: #c2d3da;
  --green: #015401;
  --subtitleColor: #404040;
  --listRow: #eaeaea;

  --borderColor: #c7c7c7;
  --borderRadius: 5px;

  /* Page Labels */
  --headingD: 25px;
  --headingM: 15px;

  /* Section Labels */
  --subHeadD: 20px;
  --subHeadM: 15px;

  /* Card Description Labels & Button Fonts */
  --fontD: 17px;
  --fontM: 12px;

  /* Icon Sizes */
  --iconD: 35px;
  --iconM: 25px;

  --paraD: 10.5px;
}
h1,
h2,
h3,
h4:not(.final),
h5,
h6:not(.question, .no-cap),
button,
a {
  text-transform: capitalize;
}
hr {
  border-top: solid 1px var(--borderColor);
}
p {
  font-size: var(--paraD);
}
.page {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 100vh;
  position: relative;
}
* {
  font-family: "Montserrat", sans-serif !important;
}
.italic-subtitle {
  font-style: italic;
  text-align: start;
  font-size: var(--subHeadD);
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.italic-subtitle:has(button) {
  margin-bottom: 10px;
  align-items: flex-start;
}
.pinned {
  width: calc(100% - 13rem);
}
.content-group {
  padding: 1rem;
}
.rows .content-group {
  width: 50%;
}
.container-box.resources,
.container-box.questions,
.container-box.cards {
  overflow-y: auto;
}
.container-box.resources {
  max-height: 13rem;
  height: 100%;
}
.container-box.questions {
  max-height: calc(100vh - 38rem);
  height: 100%;
}
.container-box.cards {
  max-height: calc(100vh - 33rem);
  height: 100%;
}
.content-group .container-box:has(.loader) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 7rem;
}
.card-content > div.rows {
  display: flex;
  gap: 3px;
}
html body {
  background-color: #eaeaea;
  color: var(--text);
  overflow-y: auto;
}
html,
:is(body, #root):has(span.access-container, #services-page, #playing-mode),
div:has(#playing-mode) {
  height: 100%;
  width: 100%;
}

.mobile-menu {
  top: 3.5rem;
  right: 1rem;
}
.mobile.fab,
.mobile-menu {
  display: none;
}
.mobile-menu ul,
.popover-menu ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.spacer {
  padding: 0.8rem;
  width: 2.85rem;
  height: 3rem;
}
.mobile-menu ul li,
.popover-menu ul li {
  padding: 0.5rem 1rem;
  font-size: var(--headingM);
}
.mobile-menu ul li:not(li.no-border),
.popover-menu ul li:not(li.no-border) {
  border-bottom: 1px solid var(--borderColor);
  font-size: var(--fontM);
}
.icon {
  width: var(--iconD);
  height: var(--iconD);
}

.fab-icon {
  width: 40px;
  height: 40px;
}
svg.fab-icon {
  font-size: 40px;
}
.ghost-section {
  background-color: var(--ghostBlue);
  padding: 6px 6px 6px 16px;
  border-radius: var(--borderRadius);
  color: var(--blue);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
div > :has(span.access-container) {
  height: -webkit-fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
div.page-box {
  width: -webkit-fill-available;
}
div.page-box .card-page {
  display: flex;
  flex-direction: column;
}
.float-actions {
  justify-content: center;
  background-color: white;
  width: 100%;
  gap: 1rem;
}
.card-content {
  flex: 1 1 auto;
  padding-bottom: 0 !important;
}
div.page-box {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: -webkit-fill-available;
}
div.page-box:has(.card-page) {
  min-height: fit-content;
}
.card-page {
  flex: 1 1 auto;
}
div.page-box div.around-exams {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
input,
label[data-shrink="false"],
label:has(input[type="checkbox"]) {
  font-size: var(--fontD) !important;
}
#footer footer {
  text-align: center;
  padding: 0.1rem 0.7rem;
  border-radius: 30px 30px 0 0;
  font-size: 7px;
  color: var(--subtitleColor);
  background-color: rgb(255, 255, 255);
  box-shadow: var(--box-shadow);
}
#footer {
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
}
.page:has(#nav-bar.active) #footer {
  width: calc(100% - 12.5rem);
}
.page:has(#nav-bar) #footer {
  width: calc(100% - 7.5rem);
}
.nav-logo {
  width: auto;
  height: 60px;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
  height: fit-content;
}
.hori-nav {
  background-color: white;
  box-shadow: var(--box-shadow);
  padding-right: 1rem;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar {
  width: 5px;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #060f1667;
  border-radius: 10px;
}
label.study-dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
}

label:has(input[type="checkbox"]:disabled) {
  cursor: not-allowed;
  opacity: 0.5;
}
.flash-top {
  margin-bottom: 1rem;
}
*:disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}
.heading {
  text-align: center;
  margin: 1rem 0rem;
  font-size: var(--headingD);
}
.category-decks,
#card-view {
  display: flex;
  flex-wrap: wrap;
  margin: 3rem 0.2rem 2rem 0.2rem;
  justify-content: center;
  gap: 1rem;
}
h4 {
  text-align: center;
  margin-bottom: 0px;
}
#market-page ul,
#market-page ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#market-page ul {
  padding-left: 0;
  list-style-type: none;
  gap: 1rem;
}
.group {
  display: flex;
  gap: 1rem;
}
.button-link:hover {
  color: white;
}
.no-decor-link {
  text-decoration: none;
  color: inherit;
}
a:not(.button-link) {
  color: var(--text) !important;
  text-underline-offset: 0.2rem;
}
.flash-card-logo {
  filter: brightness(85%) contrast(150%) saturate(200%) hue-rotate(190deg) !important;
}
.css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root,
#menu- {
  z-index: 10000001 !important;
}
/* ------------------------------------------------------------------------- */

@media only screen and (max-device-width: 768px) and (orientation: landscape) {
  div > :has(span.access-container) {
    overflow-y: auto;
  }
}
@media screen and (max-width: 950px) {
  .page {
    flex-direction: column;
  }
  div.page-box:not(#flashcards .page-box) {
    padding: 4rem 1rem 1rem 1rem;
  }
  #flashcards .page-box {
    padding: 4rem 1rem;
  }
  div.exam-box {
    display: flex;
    flex-direction: column;
  }
  .content-group {
    padding: 1rem;
  }
  .rows .content-group {
    width: 100%;
  }
  .icon {
    height: var(--iconM);
    width: var(--iconM);
  }
  .fab-icon {
    width: 25px;
    height: 25px;
  }
  .fab-icon svg {
    font-size: 25px;
  }
  .mobile-menu,
  .mobile.fab {
    display: flex;
    z-index: 101;
  }
  .desktop {
    display: none;
  }
  .page:has(#nav-bar.active, #nav-bar) #footer {
    left: 0;
    width: -webkit-fill-available;
  }
}
@media screen and (max-width: 700px) {
  .card-content > div:not(.card-alert) {
    flex-direction: column;
  }
}
@media screen and (max-width: 688px) {
  .button-text {
    display: none;
    visibility: hidden;
  }
  .card-alert .MuiAlert-message {
    width: calc(100% - 34px);
  }
  .card-alert {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 450px) {
  .heading,
  h5 {
    font-size: var(--headingM) !important;
  }
  .italic-subtitle {
    font-size: var(--subHeadM);
    margin-bottom: 15px;
  }
  .italic-subtitle:has(button) {
    align-items: center;
    margin-bottom: 6px;
  }
  .card-content hr {
    margin: 0;
  }
  .card-alert:not(.card-alert.flash) {
    margin: 1rem 1rem 0;
  }
  .card-alert * {
    font-size: var(--fontM);
  }
  .card-content {
    padding: 0px !important;
  }
  a,
  .ellipsis,
  h6,
  input,
  button,
  label[data-shrink="false"],
  label:has(input[type="checkbox"]) {
    font-size: var(--fontM) !important;
  }
}
