:root {
  --box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);

  --text: #060f16;
  --red: #8e161f;
  --blue: #0c4f6c;
  --gold: #8b6e10;
  --icon: #7d7d7d;
  --ghostBlue: #c2d3da;
  --green: #015401;
  --subtitleColor: #404040;
  --listRow: #eaeaea;

  --borderColor: #c7c7c7;
  --borderRadius: 5px;

  /* Page Labels */
  --headingD: 25px;
  --headingM: 15px;

  /* Section Labels */
  --subHeadD: 20px;
  --subHeadM: 15px;

  /* Card Description Labels & Button Fonts */
  --fontD: 17px;
  --fontM: 12px;

  /* Icon Sizes */
  --iconD: 35px;
  --iconM: 25px;

  --paraD: 10.5px;
}
h1,
h2,
h3,
h4:not(.final),
h5,
h6:not(.question, .no-cap),
button,
a {
  text-transform: capitalize;
}
hr {
  border-top: solid 1px var(--borderColor);
}
p {
  font-size: var(--paraD);
}
.page {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 100vh;
  position: relative;
}
* {
  font-family: "Montserrat", sans-serif !important;
}
.italic-subtitle {
  font-style: italic;
  text-align: start;
  font-size: var(--subHeadD);
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.italic-subtitle:has(button) {
  margin-bottom: 10px;
  align-items: flex-start;
}
.pinned {
  width: calc(100% - 13rem);
}
.content-group {
  padding: 1rem;
}
.rows .content-group {
  width: 50%;
}
.container-box.resources,
.container-box.questions,
.container-box.cards {
  overflow-y: auto;
}
.container-box.resources {
  max-height: 13rem;
  height: 100%;
}
.container-box.questions {
  max-height: calc(100vh - 38rem);
  height: 100%;
}
.container-box.cards {
  max-height: calc(100vh - 33rem);
  height: 100%;
}
.content-group .container-box:has(.loader) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 7rem;
}
.card-content > div.rows {
  display: flex;
  grid-gap: 3px;
  gap: 3px;
}
html body {
  background-color: #eaeaea;
  color: var(--text);
  overflow-y: auto;
}
html,
:is(body, #root):has(span.access-container, #services-page, #playing-mode),
div:has(#playing-mode) {
  height: 100%;
  width: 100%;
}

.mobile-menu {
  top: 3.5rem;
  right: 1rem;
}
.mobile.fab,
.mobile-menu {
  display: none;
}
.mobile-menu ul,
.popover-menu ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.spacer {
  padding: 0.8rem;
  width: 2.85rem;
  height: 3rem;
}
.mobile-menu ul li,
.popover-menu ul li {
  padding: 0.5rem 1rem;
  font-size: var(--headingM);
}
.mobile-menu ul li:not(li.no-border),
.popover-menu ul li:not(li.no-border) {
  border-bottom: 1px solid var(--borderColor);
  font-size: var(--fontM);
}
.icon {
  width: var(--iconD);
  height: var(--iconD);
}

.fab-icon {
  width: 40px;
  height: 40px;
}
svg.fab-icon {
  font-size: 40px;
}
.ghost-section {
  background-color: var(--ghostBlue);
  padding: 6px 6px 6px 16px;
  border-radius: var(--borderRadius);
  color: var(--blue);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
div > :has(span.access-container) {
  height: -webkit-fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
div.page-box {
  width: -webkit-fill-available;
}
div.page-box .card-page {
  display: flex;
  flex-direction: column;
}
.float-actions {
  justify-content: center;
  background-color: white;
  width: 100%;
  grid-gap: 1rem;
  gap: 1rem;
}
.card-content {
  flex: 1 1 auto;
  padding-bottom: 0 !important;
}
div.page-box {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: -webkit-fill-available;
}
div.page-box:has(.card-page) {
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
}
.card-page {
  flex: 1 1 auto;
}
div.page-box div.around-exams {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
input,
label[data-shrink="false"],
label:has(input[type="checkbox"]) {
  font-size: var(--fontD) !important;
}
#footer footer {
  text-align: center;
  padding: 0.1rem 0.7rem;
  border-radius: 30px 30px 0 0;
  font-size: 7px;
  color: var(--subtitleColor);
  background-color: rgb(255, 255, 255);
  box-shadow: var(--box-shadow);
}
#footer {
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
}
.page:has(#nav-bar.active) #footer {
  width: calc(100% - 12.5rem);
}
.page:has(#nav-bar) #footer {
  width: calc(100% - 7.5rem);
}
.nav-logo {
  width: auto;
  height: 60px;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.hori-nav {
  background-color: white;
  box-shadow: var(--box-shadow);
  padding-right: 1rem;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar {
  width: 5px;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #060f1667;
  border-radius: 10px;
}
label.study-dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  width: 100%;
}

label:has(input[type="checkbox"]:disabled) {
  cursor: not-allowed;
  opacity: 0.5;
}
.flash-top {
  margin-bottom: 1rem;
}
*:disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}
.heading {
  text-align: center;
  margin: 1rem 0rem;
  font-size: var(--headingD);
}
.category-decks,
#card-view {
  display: flex;
  flex-wrap: wrap;
  margin: 3rem 0.2rem 2rem 0.2rem;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
}
h4 {
  text-align: center;
  margin-bottom: 0px;
}
#market-page ul,
#market-page ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#market-page ul {
  padding-left: 0;
  list-style-type: none;
  grid-gap: 1rem;
  gap: 1rem;
}
.group {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}
.button-link:hover {
  color: white;
}
.no-decor-link {
  text-decoration: none;
  color: inherit;
}
a:not(.button-link) {
  color: var(--text) !important;
  text-underline-offset: 0.2rem;
}
.flash-card-logo {
  filter: brightness(85%) contrast(150%) saturate(200%) hue-rotate(190deg) !important;
}
.css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root,
#menu- {
  z-index: 10000001 !important;
}
/* ------------------------------------------------------------------------- */

@media only screen and (max-device-width: 768px) and (orientation: landscape) {
  div > :has(span.access-container) {
    overflow-y: auto;
  }
}
@media screen and (max-width: 950px) {
  .page {
    flex-direction: column;
  }
  div.page-box:not(#flashcards .page-box) {
    padding: 4rem 1rem 1rem 1rem;
  }
  #flashcards .page-box {
    padding: 4rem 1rem;
  }
  div.exam-box {
    display: flex;
    flex-direction: column;
  }
  .content-group {
    padding: 1rem;
  }
  .rows .content-group {
    width: 100%;
  }
  .icon {
    height: var(--iconM);
    width: var(--iconM);
  }
  .fab-icon {
    width: 25px;
    height: 25px;
  }
  .fab-icon svg {
    font-size: 25px;
  }
  .mobile-menu,
  .mobile.fab {
    display: flex;
    z-index: 101;
  }
  .desktop {
    display: none;
  }
  .page:has(#nav-bar.active, #nav-bar) #footer {
    left: 0;
    width: -webkit-fill-available;
  }
}
@media screen and (max-width: 700px) {
  .card-content > div:not(.card-alert) {
    flex-direction: column;
  }
}
@media screen and (max-width: 688px) {
  .button-text {
    display: none;
    visibility: hidden;
  }
  .card-alert .MuiAlert-message {
    width: calc(100% - 34px);
  }
  .card-alert {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 450px) {
  .heading,
  h5 {
    font-size: var(--headingM) !important;
  }
  .italic-subtitle {
    font-size: var(--subHeadM);
    margin-bottom: 15px;
  }
  .italic-subtitle:has(button) {
    align-items: center;
    margin-bottom: 6px;
  }
  .card-content hr {
    margin: 0;
  }
  .card-alert:not(.card-alert.flash) {
    margin: 1rem 1rem 0;
  }
  .card-alert * {
    font-size: var(--fontM);
  }
  .card-content {
    padding: 0px !important;
  }
  a,
  .ellipsis,
  h6,
  input,
  button,
  label[data-shrink="false"],
  label:has(input[type="checkbox"]) {
    font-size: var(--fontM) !important;
  }
}

span.access-container form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  grid-gap: 1rem;
  gap: 1rem;
}
span.access-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.access-header div:has(img) {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.access-header img {
  width: 5rem;
}
.access-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form-container {
  background-color: white;
  box-shadow: var(--box-shadow);
  border: none;
  border-radius: var(--borderRadius);
  padding: 1rem 2rem;
  width: 30rem;
}
h2.form-items {
  margin-bottom: 0rem;
}
.cta-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
p.note {
  font-size: 12px;
  width: 100%;
  text-align: center;
}

.name-container {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

@media screen and (max-height: 450px) and (orientation: landscape) {
  .form-container {
    overflow: auto;
    width: 100%;
  }
  .name-container div {
    width: 100%;
  }
}
@media screen and (max-width: 547px) {
  .form-container {
    overflow: auto;
    width: 100%;
  }
  .name-container {
    flex-direction: column;
    align-items: stretch;
  }
}
@media screen and (max-width: 406px) {
  .cta-actions {
    flex-direction: column;
  }
}

div.exams-container img {
  width: 13rem;
}

div.exams-container {
  display: flex;
  position: relative;
  grid-gap: 1rem;
  gap: 1rem;
  flex-direction: column;
}
div.exams-container div.logo-container img {
  background-color: #f4f4f4;
  width: 11rem;
  height: 6rem;
  object-fit: cover;
  border-radius: var(--borderRadius);
}
#loading {
  align-items: center;
}
div.exam-flex-box {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: white;
  border-radius: var(--borderRadius);
  box-shadow: var(--box-shadow);
}

div.logo-container {
  width: 11rem;
  margin-right: 1rem;
}

div.middle-items h2 {
  font-size: var(--subHeadD);
  text-transform: capitalize;
}
div.middle-items p {
  font-size: var(--paraD);
  color: var(--subtitleColor);
}
div.around-no-exams {
  flex: 1 1 auto;
  margin: 1rem 0rem;
}

div.exam-box:has(div.around-no-exams) {
  height: -webkit-fill-available;
}

div#no-exams,
div#loading,
div#flash-loading {
  text-align: center;
  padding: 5rem;
}
/* MINIMIZED WINDOW STILL DESKTOP VIEW CSS */
/* ---------------------------------------------- */

@media screen and (max-width: 635px) {
  div.middle-items h2 {
    font-size: var(--subHeadM);
  }
}
@media screen and (max-width: 500px) {
  div.exam-flex-box {
    flex-direction: column;
    padding: 0;
  }
  div.exam-flex-box div.middle-items,
  a.exam-flex-box.no-decor-link,
  div.logo-container,
  div.exams-container div.logo-container img {
    width: 100%;
  }
  div.exam-flex-box div.middle-items {
    padding: 1rem;
  }
  div.logo-container {
    margin-right: 0;
  }
  div.exams-container div.logo-container img {
    height: 11rem;
    border-radius: var(--borderRadius) var(--borderRadius) 0 0;
  }
}

#nav-bar {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: #fbfbff;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 5rem;
  box-shadow: var(--box-shadow);
  z-index: 100;
}
#nav-bar.active {
  width: 10rem;
}
#menu-btn-mobile {
  display: none !important;
}
#nav-bar .icon {
  width: var(--iconD);
  height: var(--iconD);
  filter: drop-shadow(0.8px 0.8px 0.8px #7d7d7d);
}
#nav-bar a {
  text-decoration: none;
}
#nav-bar li {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-link {
  text-decoration: none;
  border:none;
  background-color: transparent;
  color: var(--text);
  font-size: var(--fontM);
  cursor: pointer;
  padding: 0;
}
.menu-link:hover:not(:disabled) {
  color: var(--blue);
}
.menu-link:disabled {
  color: #7d7d7d
}
#nav-bar img.logo {
  width: 5rem;
  margin-bottom: 0.5rem;
  filter: none;
}
#nav-bar a:has(img.logo) {
  transition: none !important;
}
#nav-bar a:has(img.logo):hover {
  transform: none !important;
}
#nav-bar li.part-one {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#nav-bar li.part-two {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 0.4rem;
  gap: 0.4rem;
}
#nav-bar .description {
  font-size: 12px;
  max-width: 4rem;
  margin-bottom: 0;
  color: var(--text);
  text-align: start;
  margin-left: 1rem;
}

#nav-bar li button,
#nav-bar a {
  border: none;
  display: flex;
  align-items: center;
  background: transparent;
  transition: all 0.2s linear;
}

#nav-bar li button:not(#menu-btn-mobile):hover,
#nav-bar a:not(.flash-items a):hover {
  transform: scale(1.3);
}

#nav-bar li.part-three {
  padding: 0rem 0rem 0.5rem 0rem;
}
.hamburger {
  cursor: pointer;
  width: 24px;
  height: 18px;
  transition: all 0.25s;
  position: relative;
}
#menu-btn-mobile.hamburger {
  width: 20px;
}
#menu-btn-mobile .hamburger-top,
#menu-btn-mobile .hamburger-middle,
#menu-btn-mobile .hamburger-bottom {
  width: 20px;
}
.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
  position: absolute;
  width: 24px;
  height: 2px;
  top: 0;
  left: 0;
  background: #060f16;
  transform: rotate(0);
  transition: all 0.5s;
  filter: drop-shadow(1px 1px 1px #7d7d7d);
}

.hamburger-middle {
  transform: translateY(7px);
}
.hamburger-bottom {
  transform: translateY(14px);
}

.open {
  transform: rotate(90deg) translateY(0px);
}

.open .hamburger-top {
  transform: rotate(45deg) translateY(6px) translateX(6px);
}

.open .hamburger-middle {
  display: none;
}

.open .hamburger-bottom {
  transform: rotate(-45deg) translateY(6px) translateX(-6px);
}
@media screen and (max-width: 1059px) {
  #nav-bar img.logo {
    width: 4rem;
  }
  #nav-bar.active {
    width: 8rem;
  }
  #nav-bar {
    width: 4rem;
  }
}
/* MOBILE CSS */
@media screen and (max-width: 950px) {
  #nav-bar li.part-two,
  #nav-bar li.part-three .desktop,
  #menu-btn {
    display: none !important;
    -webkit-appearance: none;
            appearance: none;
    visibility: hidden;
  }
  #nav-bar li.part-three {
    padding: 0rem 1rem;
  }
  #menu-btn-mobile {
    display: flex !important;
  }
  #nav-bar li.part-one a img.logo {
    width: 3rem;
  }
  #nav-bar,
  #nav-bar.active {
    height: 3rem;
    width: 100%;
    flex-direction: row;
    position: fixed;
  }
  #nav-bar a:hover {
    transform: none;
  }
  #nav-bar img.logo {
    width: 4rem;
    margin-bottom: 0rem;
  }
}

#profile-modal,
#upload-modal,
#delete-exam-modal,
#exam-id-modal,
#exam-modal,
#resource-modal,
#choice-modal,
#add-modal,
#delete-modal,
#card-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: 200ms ease-in-out;
  border-radius: var(--borderRadius);
  box-shadow: var(--box-shadow);
  background-color: white;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-width: 100%;
  color: #060f16;
  z-index: 10000001;
  border: none;
}

#choice-modal,
#card-modal {
  max-width: 600px;
  width: 100%;
}

#add-modal {
  max-width: 350px;
  width: 100%;
}
:is(#profile-modal, #upload-modal, #delete-exam-modal, #exam-id-modal, #exam-modal, #resource-modal, #choice-modal, #add-modal, #delete-modal, #card-modal).active {
  transform: translate(-50%, -50%) scale(1);
}

#profile-overlay,
#upload-overlay,
#delete-exam-overlay,
#exam-id-overlay,
#exam-overlay,
#resource-overlay,
#choice-overlay,
#add-overlay,
#delete-overlay,
#card-overlay {
  position: fixed;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 200ms ease-in-out;
  background-color: #060f168a;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  pointer-events: none;
  z-index: 10000000;
}

:is(#profile-overlay, #upload-overlay, #delete-exam-overlay, #exam-id-overlay, #exam-overlay, #resource-overlay, #choice-overlay, #add-overlay, #delete-overlay, #card-overlay).active {
  pointer-events: all;
  opacity: 1;
}

div.header h1 {
  font-size: var(--headingD);
  margin-bottom: 0;
}
div.header {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--borderColor);
}

.close-modal {
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  color: #060f16;
  font-size: 2rem !important;
  transition: all 0.2s linear;
  width: auto;
  height: 2rem;
  display: flex;
  align-items: center;
}
.close-modal:not(#back-button):hover {
  transform: scale(1.3) rotate(180deg);
}
#choice-modal .close-modal#back-button:hover {
  transform: scale(1.3);
}

#card-modal div.modal-body div.top-items {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%;
}

.question-input textarea {
  overflow: hidden;
}
#profile-modal div.modal-body > div,
:is(#upload-modal, #delete-exam-modal, #choice-modal) div.modal-body {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 1rem;
  grid-column-gap: 1rem;
  column-gap: 1rem;
  align-items: center;
  align-items: flex-end;
}
#upload-modal div.modal-body {
  align-items: stretch;
  flex-direction: column;
}
#resource-modal div.modal-body {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: stretch;
}
#upload-modal div.modal-body .alert-box,
#upload-modal div.modal-body .error {
  width: 100% !important;
}
:is(#delete-exam-modal, #exam-id-modal, #exam-modal, #choice-modal, #delete-modal, #card-modal, #add-modal)
  div.modal-body {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: stretch;
}
#delete-exam-modal div.modal-body div.option-buttons,
#delete-modal div.modal-body div.option-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
}
#delete-exam-modal
  > div.modal-body
  > div.option-buttons
  button:not(button.close-modal),
#delete-modal
  > div.modal-body
  > div.option-buttons
  button:not(button.close-modal) {
  margin: 1rem 1rem 0rem 1rem;
}

:is(#profile-modal, #upload-modal, #delete-exam-modal, #exam-modal, #add-modal, #delete-modal, #card-modal)
  button.modal-save,
#delete-exam-modal button.cancel,
#delete-modal button.cancel {
  width: 15rem;
  color: #fbfbff;
  border-radius: var(--borderRadius);
  height: 3.5rem;
  background-color: #b80c09;
  box-shadow: var(--box-shadow);
  border: none;
  align-items: center;
  display: flex;
  justify-content: center;
}
#card-modal button.modal-save {
  min-width: 17rem;
  width: 100%;
}
#exam-modal button.modal-save {
  background-color: #0b4f6c;
}
:is(#exam-modal, #add-modal) button.modal-save {
  width: 30rem;
}
#delete-exam-modal button.cancel,
#delete-modal button.cancel {
  background-color: #0b4f6c;
}

#delete-exam-modal button.cancel:hover,
#delete-modal button.cancel:hover,
#exam-modal button.modal-save:hover {
  transform: none;
  background-color: #072a39;
}

button b {
  font-size: small;
}
button.modal-save:hover {
  background-color: #6a0807;
  transform: none;
}

#resource-modal div.modal-body {
  padding: 1rem;
}

table {
  margin-bottom: 0rem;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  padding: 1rem;
}
thead tr {
  background-color: #fbfbff;
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  z-index: 1;
}
tr:nth-child(odd):not(thead tr) {
  background-color: #e8e8e8;
}
table td.td-question {
  display: block;
  width: 42.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div.modal-body .container-items {
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  grid-gap: 1rem;
  gap: 1rem;
}
#choice-modal .choices {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  flex-direction: column;
  padding-left: 0.5rem;
}
p.match-error {
  width: auto !important;
  text-align: center;
  padding-bottom: 0rem;
  text-transform: uppercase;
  color: #060f16;
  font-size: small !important;
}
div.table-wrapper:has(table > tbody > tr:nth-child(7)) {
  height: 19rem;
  overflow-y: scroll;
}
div.table-wrapper {
  overflow-x: hidden;
  width: 100%;
  border: none;
}
div.table-wrapper tr td button {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  color: #fbfbff;
  align-items: center;
  border: none;
  transition: all 0.2s linear;
  border-radius: var(--borderRadius);
  box-shadow: var(--box-shadow);
}
div.table-wrapper tr td:has(button) {
  padding: 0.6rem 0.5rem;
}
div.table-wrapper tr td:nth-child(3) button {
  background-color: #01baef;
}
div.table-wrapper tr td:nth-child(4) button {
  background-color: #0c0f16;
}
div.table-wrapper tr td button:hover {
  transform: scale(1.2);
}
.modal-save:disabled {
  opacity: 0.5 !important;
}

@media screen and (max-width: 662px) {
  #save-button {
    width: 100% !important;
  }
}
@media screen and (max-width: 645px) {
  #choice-modal,
  #card-modal {
    max-width: 90%;
  }
}
@media screen and (max-width: 622px) {
  #delete-modal div.modal-body {
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  div.option-buttons {
    flex-direction: column;
  }
}
@media screen and (max-width: 575px) {
  :is(#exam-modal, #add-modal) button.modal-save {
    width: 20rem;
  }
}
@media screen and (max-width: 543px) {
  #resource-modal div.modal-body {
    flex-direction: column;
  }
  #resource-modal {
    width: 18rem;
  }
}

@media screen and (max-width: 400px) {
  :is(#exam-modal, #add-modal) button.modal-save {
    width: 15rem;
  }
  #add-modal {
    max-width: 90%;
  }
}
@media screen and (max-width: 450px) {
  div.header {
    padding: 10px;
  }
  div.header h1 {
    font-size: var(--headingM);
  }
}
@media screen and (max-height: 480px) and (orientation: landscape) {
  #card-modal.active {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  #card-modal div.modal-body > div {
    width: 100%;
  }
}

div.search-bar button,
div.search input {
  border: none;
  padding: 0.8rem;
  height: 3rem;
}
div.search input {
  border-radius: var(--borderRadius);
  border: none;
  width: 40rem;
}

div.search input:focus {
  outline: none;
}
div.search {
  background-color: white;
  border-radius: var(--borderRadius);
  box-shadow: var(--box-shadow);
}
div.search-bar {
  display: flex;
  border-radius: var(--borderRadius);
  border: none;
  align-items: center;
  justify-content: space-between;
  grid-gap: 1rem;
  gap: 1rem;
}

div.around-search-bar button {
  font-size: 30px;
  color: #7d7d7d;
}
div.around-search-bar button:hover {
  background-color: transparent;
  color: #000000;
}
div.search-bar button,
div.search {
  display: flex;
  width: -webkit-max-content;
  width: max-content;
  justify-content: center;
  align-items: center;
}
div.search {
  justify-content: space-between;
}
@media screen and (max-width: 1095px) {
  div.filter-container div.search-bar {
    flex: 1 1 auto;
  }
  div.filter-container form,
  div.filter-container div.search,
  div.filter-container div.search input {
    width: 100% !important;
  }
  .filter-container {
    flex-wrap: nowrap;
  }
}
@media screen and (max-width: 950px) {
  div.search-bar#exams-search,
  #exams-search div.search {
    width: 100%;
  }
}

div.around-active-exam {
  background-color: #fbfbff;
  display: flex;
  border-radius: var(--borderRadius);
  box-shadow: var(--box-shadow);
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 auto;
}
div.active-container {
  display: flex;
  padding: 1rem;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

div.around-active-exam div.top-part img.logo-image {
  opacity: 0.5;
  width: 5%;
  height: auto;
}
div.around-active-exam div.top-part {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0rem 0.2rem;
}

div.middle-part {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  overflow-y: auto;
  height: 28rem;
  justify-content: center;
  padding: 0.5rem;
}
div.middle-part h6.question,
div.middle-part h4.final {
  text-align: center;
  word-break: break-word;
  white-space: normal;
  margin: 0rem 1.5rem 1rem 1.5rem;
}
div.middle-part h4.final {
  margin: 0rem;
}
div.middle-part button.choices:not(:has(.highlighted)) {
  border: solid 1px #0000003b;
}
div.middle-part button.choices {
  padding: 0.5rem;
  border-radius: var(--borderRadius);
  word-wrap: break-word;
  background-color: transparent;
  margin-bottom: 0.5rem;
  display: flex;
  width: 100%;
  flex-direction: row;
  text-align: start;
  align-items: center;
  word-break: break-word;
  white-space: normal;
  transition: all 0.2s linear;
}
div.middle-part button.choices:hover {
  box-shadow: var(--box-shadow);
}
div.middle-part button.choices.highlighted input::before {
  opacity: 1; /* Show the inner circle when highlighted */
}
div.middle-part button.choices.highlighted[disabled] input {
  background: inherit;
}
div.middle-part button.choices p {
  display: flex;
  align-items: center;
}

div.middle-part button.choices p:last-child::after {
  visibility: visible;
}
div.bottom-part {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #0b4f6c;
  height: 3rem;
  align-items: center;
  border-radius: 0 0 var(--borderRadius) var(--borderRadius);
  padding: 0.5rem;
}

div.bottom-part button {
  width: 12rem;
  height: 2rem;
}

p.logo-stamp {
  border-radius: 20px;
  display: flex;
  align-items: center;
  direction: ltr;
  padding: 4px 8px;
  text-transform: capitalize;
  font-weight: 400;
  background: white;
  box-shadow: var(--box-shadow);
  justify-content: center;
}
a.around-stamp {
  text-decoration: none;
  width: 12rem;
  display: flex;
  height: 2rem;
  color: var(--text);
  align-items: center;
}
a.around-stamp:hover {
  color: var(--blue);
}
p.logo-stamp b {
  color: var(--red);
}
p.logo-stamp img {
  width: 10%;
}

div#result-alert.correct:before,
div#result-alert.wrong:before {
  -webkit-appearance: all;
          appearance: all;
  border: var(--green) solid 1px;
  width: 10rem;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
  height: 2rem;
  background-color: var(--green);
  border-radius: var(--borderRadius);
  content: "Correct";
  font-size: small;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
div#result-alert.wrong:before {
  content: "Incorrect";
  border: var(--red) solid 1px;
  background-color: var(--red);
}

div#result-alert {
  -webkit-appearance: none;
          appearance: none;
}

@media screen and (max-width: 980px) {
  div.around-active-exam div.top-part img.logo-image {
    width: 8%;
  }
  div.middle-part h4.final {
    font-size: var(--headingM);
  }
}
@media screen and (max-width: 550px) {
  div.bottom-part button,
  a.around-stamp {
    width: 8rem;
  }
}
@media screen and (max-width: 450px) {
  div.bottom-part button,
  a.around-stamp {
    width: 6rem;
  }
  p.logo-stamp img {
    width: 15%
  }
}

.category-decks {
  grid-row-gap: 4rem;
  row-gap: 4rem;
  grid-column-gap: 3rem;
  column-gap: 3rem;
  margin: 0;
  padding: 3rem 1.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, 360px);
  justify-items: center;
}

div#no-exams,
div#flash-loading {
  background-color: transparent !important;
  box-shadow: none !important;
  text-align: center;
  padding: 5rem;
}
div#flash-loading {
  align-items: center;
}
#no-items {
  background-color: white;
  border-radius: var(--borderRadius);
  padding: 0.5rem 1rem;
}
.category-decks:has(#no-items) {
  margin: 0rem 1.5rem;
  height: 8rem;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.deck-card {
  background-color: white;
  padding: 1rem;
  border-radius: var(--borderRadius);
  box-shadow: var(--box-shadow);
  border: none;
  height: 12.3rem;
  width: 360px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  position: relative;
  cursor: pointer;
}

.deck-card .more {
  position: absolute;
  top: -0.7rem;
  right: 0.5rem;
}
.deck-card::before,
.deck-card::after {
  content: "";
  position: absolute;
  width: inherit;
  height: inherit;
  background-color: inherit;
  border-radius: var(--borderRadius);
  box-shadow: var(--box-shadow);
}

.deck-card::before {
  transform: rotate(5deg); /* Adjust the rotation angle as needed */
  z-index: -1; /* Set the z-index to 0 for the card with a positive angle */
}

.deck-card::after {
  transform: rotate(-5deg); /* Adjust the rotation angle as needed */
  z-index: -1; /* Set the z-index to 0 for the card with a negative angle */
}
@media screen and (max-width: 476px) {
  .category-decks {
    padding: 1rem 0.5rem;
    grid-template-columns: repeat(auto-fill, 300px);
  }
  .deck-card,
  .deck-card-container {
    width: 300px;
    height: 10rem;
  }
}

@media screen and (max-width: 365px) {
  .category-decks {
    grid-template-columns: repeat(auto-fill, 250px);
  }
  .deck-card,
  .deck-card-container {
    width: 250px;
    height: 9rem;
  }
}

@media screen and (max-width: 323px) {
  .deck-card {
    width: 100%;
  }
  .category-decks {
    flex-direction: column;
    align-items: stretch;
  }
}

.around-dropdown {
  display: flex;
  align-items: center;
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
}

.filter-container,
.filter-ctas {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  grid-gap: 1rem;
  gap: 1rem;
}
.filter-subtitle {
  padding: 0 9px !important;
  font-style: italic;
  margin-bottom: 5px;
}
.filter-ctas {
  height: 3rem;
}

.filter-options {
  padding: 9px 0px;
  width: 13rem;
  max-height: 35rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}
.filter-container {
  flex-wrap: wrap;
}

.filter-menu .header {
  padding: 0 0px 0px 9px;
}

.filter-menu .header h6,
.filter-subtitle,
.filter-options span {
  font-size: var(--headingM) !important;
}

.flash-card:has(.card-front) {
  background-image: linear-gradient(
      180deg,
      white 3rem,
      #8e161e98 calc(3rem),
      #8e161e98 calc(3rem + 2px),
      transparent 1px
    ),
    repeating-linear-gradient(
      0deg,
      transparent,
      transparent 1.5rem,
      #ddd 1px,
      #ddd calc(1.5rem + 1px)
    );
  padding: 0.4rem;
  z-index: 1;
  cursor: pointer;
}
.card-front {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: -webkit-fill-available;
}
.flash-card h3 {
  text-align: center;
  margin-bottom: 0px;
}

.flash-card p {
  font-size: 0.8rem;
  margin-bottom: 0px;
  overflow-wrap: break-word;
  line-height: 1.5rem;
  overflow-y: auto;
}

.flash-card {
  background-color: #fbfbff;
  box-shadow: var(--box-shadow);
  height: 12.3rem;
  width: 360px;
  position: relative;
  overflow: auto;
  min-width: 285px;
  border-radius: var(--borderRadius);
}

.card-back {
  display: flex;
  height: 100%;
  width: 100%;
  overflow-wrap: anywhere;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 0.8rem;
}
.no-cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.no-cards h1 {
  margin-bottom: 0px;
}
#no-cards-view {
  text-align: center;
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 5rem 0rem;
}
.flash-card:has(.card-front) .more {
  float: right;
  padding: 0;
}
.flash-card:has(.card-back) .more {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.4rem;
}
.study-icon {
  position: absolute;
  bottom: 0.4rem;
  right: 0.4rem;
}
@media screen and (max-width: 476px) {
  .flash-card,
  .card-container {
    width: 100%;
  }
}

.form__group {
  position: relative;
  padding: 9px 0 0;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 1px solid var(--borderColor);
  border-radius: var(--borderRadius);
  outline: 0;
  font-size: 16px;
  color: var(--text);
  padding: 10px;
  background: transparent;
  transition: border-color 0.2s;
  resize: none;
}
.form__field.error {
  border: 1px solid var(--red);
}
.form__field.error:focus {
  border: 2px solid var(--red);
}
.form__group:has(.form__field.error:focus) .form__label,
.form__field.error::placeholder {
  color: var(--red);
}
.form__label,
.form__field:focus ~ .form__label {
  position: absolute;
  display: none;
  top: 0px;
  padding: 0px 6px;
  left: 9px;
  transition: 0.2s;
  font-size: 12px;
  background-color: white;
  color: var(--text);
}

.form__field:focus ~ .form__label {
  color: var(--text);
}

.form__field:focus {
  border: 2px solid var(--text);
}
.form__group:has(.form__field:focus, .form__field:not(:placeholder-shown))
  .form__label {
  display: block;
}

#playing-mode .flash-card {
  height: 28.5rem;
  cursor: pointer;
  position: relative;
}

#playing-mode .flash-card,
#playing-mode .cta-buttons {
  width: 100%;
  max-width: 70rem;
}

#playing-mode .cta-buttons {
  min-width: 285px;
}
#playing-mode .flash-card:has(.card-front) {
  background-image: linear-gradient(
      180deg,
      #ffffff 3rem,
      #8e161e98 calc(3rem),
      #8e161e98 calc(3rem + 2px),
      transparent 1px
    ),
    repeating-linear-gradient(
      0deg,
      transparent,
      transparent 2.2rem,
      #ddd 1px,
      #ddd calc(2.2rem + 2px)
    );
}
#playing-mode .flash-card:has(.card-back) {
  padding: 3rem;
}

.card-count {
  position: absolute;
  bottom: 5px;
  right: 10px;
  color: var(--icon);
}
#playing-mode {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: -webkit-fill-available;
  justify-content: center;
}

#playing-mode .cta-buttons {
  padding: 0.6rem;
  margin-top: 1rem;
  height: 3.5rem;
  background-color: white;
  box-shadow: var(--box-shadow);
  border-radius: var(--borderRadius);
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-bottom: 0rem;
  justify-content: space-between;
}

#playing-mode .flash-card p:not(.card-count) {
  margin-top: 46px;
  font-size: 1.3rem;
  max-height: 100%;
  overflow-y: auto;
  line-height: 2.3rem;
}

.background {
  position: absolute;
  top: 0.1rem;
  left: 0rem;
  width: 100%;
}
.background button.hint {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  background-color: transparent;
  border: none;
}
.background .logo-image {
  opacity: 0.5;
  width: 4rem;
  height: auto;
}

#playing-mode .flash-card .card-front,
#playing-mode .flash-card .card-back {
  height: 100%;
}
@media screen and (max-width: 905px) {
  #playing-mode .flash-card .card-front p {
    font-size: 1rem;
  }
}
@media screen and (max-height: 550px) {
  #playing-mode .flash-card p {
    font-size: 1rem;
  }
}

@media screen and (max-height: 485px) {
  #playing-mode .flash-card {
    height: 23.2rem;
  }
}

@media screen and (max-height: 480px) {
  #playing-mode .flash-card {
    height: 20rem;
  }
}

@media screen and (max-height: 432px) {
  #playing-mode .flash-card {
    height: 17rem;
  }
}
@media screen and (max-height: 385px) {
  #playing-mode .flash-card {
    height: 17rem;
  }
}

@media screen and (orientation: landscape) and (max-height: 455px) {
  #playing-mode .flash-card {
    height: 100%;
  }
}

ul.list-view {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
}

ul.list-view li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  grid-gap: 5px;
  gap: 5px;
}

ul.list-view li:nth-child(odd) {
  background-color: var(--listRow);
}

