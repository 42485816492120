#playing-mode .flash-card {
  height: 28.5rem;
  cursor: pointer;
  position: relative;
}

#playing-mode .flash-card,
#playing-mode .cta-buttons {
  width: 100%;
  max-width: 70rem;
}

#playing-mode .cta-buttons {
  min-width: 285px;
}
#playing-mode .flash-card:has(.card-front) {
  background-image: linear-gradient(
      180deg,
      #ffffff 3rem,
      #8e161e98 calc(3rem),
      #8e161e98 calc(3rem + 2px),
      transparent 1px
    ),
    repeating-linear-gradient(
      0deg,
      transparent,
      transparent 2.2rem,
      #ddd 1px,
      #ddd calc(2.2rem + 2px)
    );
}
#playing-mode .flash-card:has(.card-back) {
  padding: 3rem;
}

.card-count {
  position: absolute;
  bottom: 5px;
  right: 10px;
  color: var(--icon);
}
#playing-mode {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: -webkit-fill-available;
  justify-content: center;
}

#playing-mode .cta-buttons {
  padding: 0.6rem;
  margin-top: 1rem;
  height: 3.5rem;
  background-color: white;
  box-shadow: var(--box-shadow);
  border-radius: var(--borderRadius);
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0rem;
  justify-content: space-between;
}

#playing-mode .flash-card p:not(.card-count) {
  margin-top: 46px;
  font-size: 1.3rem;
  max-height: 100%;
  overflow-y: auto;
  line-height: 2.3rem;
}

.background {
  position: absolute;
  top: 0.1rem;
  left: 0rem;
  width: 100%;
}
.background button.hint {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  background-color: transparent;
  border: none;
}
.background .logo-image {
  opacity: 0.5;
  width: 4rem;
  height: auto;
}

#playing-mode .flash-card .card-front,
#playing-mode .flash-card .card-back {
  height: 100%;
}
@media screen and (max-width: 905px) {
  #playing-mode .flash-card .card-front p {
    font-size: 1rem;
  }
}
@media screen and (max-height: 550px) {
  #playing-mode .flash-card p {
    font-size: 1rem;
  }
}

@media screen and (max-height: 485px) {
  #playing-mode .flash-card {
    height: 23.2rem;
  }
}

@media screen and (max-height: 480px) {
  #playing-mode .flash-card {
    height: 20rem;
  }
}

@media screen and (max-height: 432px) {
  #playing-mode .flash-card {
    height: 17rem;
  }
}
@media screen and (max-height: 385px) {
  #playing-mode .flash-card {
    height: 17rem;
  }
}

@media screen and (orientation: landscape) and (max-height: 455px) {
  #playing-mode .flash-card {
    height: 100%;
  }
}
